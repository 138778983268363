const binarySearchFn = require("binary-search");

export function findPressure(timestamp, timestamps, pressures) {
  if (timestamps.length !== pressures.length) {
    console.log("findPressure: length mismatch");
  }

  let x = binarySearchFn(timestamps, timestamp, function (element, needle) {
    return element - needle;
  });

  if (0 <= x) {
    return pressures[x];
  }

  x *= -1;
  x--;

  // x points to the value to the right of our value

  if (x === 0) {
    // First value, can't interpolate
    return pressures[x];
  }

  if (x === pressures.length) {
    // Last value, can't interpolate
    return pressures[x - 1];
  }

  const leftTimestamp = timestamps[x - 1];
  const rightTimestamp = timestamps[x];

  console.assert(leftTimestamp <= timestamp);
  console.assert(timestamp <= rightTimestamp);

  const timespan = rightTimestamp - leftTimestamp;
  const percent = (timestamp - leftTimestamp) / timespan;

  const leftPressure = pressures[x - 1];
  const rightPressure = pressures[x];
  const pressureSpan = rightPressure - leftPressure;

  return leftPressure + pressureSpan * percent;
}

export function createEventSeries(
  events,
  label,
  markerSize,
  markerColor,
  yaxis_name,
) {
  try {
    const series = {
      name: label,
      type: "scattergl",
      mode: "markers",
      yaxis: yaxis_name,
      marker: {
        size: markerSize,
        color: markerColor,
      },
      x: events.timestamps,
      y: events.pressures,
      showlegend: false,
      hovertemplate: label + " <extra></extra>",
    };

    if (label === "Drips") {
      series["hovertemplate"] = null;
      series["hoverinfo"] = "skip";
    }

    return series;
  } catch (e) {
    return [];
  }
}
