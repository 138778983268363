export const HPA_TO_CMH2O_CONVERSION_FACTOR = 1.019716212977928;
export const HPA_TO_MMHG_CONVERSION_FACTOR = 0.75006157584566;

const KEY_PRESSURE_UNITS = "pressureUnits";
export const LS_PRESSURE_UNIT_MM_HG = "mm_hg";
export const LS_PRESSURE_UNIT_CM_H2O = "cm_h2o";
export const LS_PRESSURE_UNIT_HPA = "hpa";

const KEY_VOLUME_PER_TIME_UNITS = "volumeUnits";
export const LS_VOLUME_UNIT_ML_HR = "ml_hr";
export const LS_VOLUME_UNIT_CC_HR = "cc_hr";

function getItemFromLocalStorage(key, defaultValue) {
  const fromStorage = localStorage.getItem(key);
  if (fromStorage == null) {
    return defaultValue;
  }
  return fromStorage;
}

export class UnitOfMeasurementExpert {
  static getPressureUnitCode() {
    return getItemFromLocalStorage(KEY_PRESSURE_UNITS, LS_PRESSURE_UNIT_MM_HG);
  }

  static setPressureUnitCode(newUnit) {
    localStorage.setItem(KEY_PRESSURE_UNITS, newUnit);
  }

  static getVolumePerHourUnitCode() {
    return getItemFromLocalStorage(
      KEY_VOLUME_PER_TIME_UNITS,
      LS_VOLUME_UNIT_ML_HR,
    );
  }

  static setVolumePerTimeUnitCode(newValue) {
    localStorage.setItem(KEY_VOLUME_PER_TIME_UNITS, newValue);
  }

  static getPressureConversionFactor() {
    const pressureUnitCode = this.getPressureUnitCode();
    if (pressureUnitCode === LS_PRESSURE_UNIT_CM_H2O) {
      return HPA_TO_CMH2O_CONVERSION_FACTOR;
    } else if (pressureUnitCode === LS_PRESSURE_UNIT_MM_HG) {
      return HPA_TO_MMHG_CONVERSION_FACTOR;
    } else if (pressureUnitCode === LS_PRESSURE_UNIT_HPA) {
      return 1.0;
    }
    return 1.0;
  }

  static getPressureUnitString() {
    const pressureUnitCode = this.getPressureUnitCode();
    if (pressureUnitCode === LS_PRESSURE_UNIT_CM_H2O) {
      return "cmH2O";
    } else if (pressureUnitCode === LS_PRESSURE_UNIT_MM_HG) {
      return "mmHg";
    } else if (pressureUnitCode === LS_PRESSURE_UNIT_HPA) {
      return "hPa";
    }
    return "?";
  }

  static getVolumeUnitString() {
    const volumePerHourUnitCode = this.getVolumePerHourUnitCode();
    if (volumePerHourUnitCode === LS_VOLUME_UNIT_ML_HR) {
      return "ml";
    } else if (volumePerHourUnitCode === LS_VOLUME_UNIT_CC_HR) {
      return "cc";
    }
    return "?";
  }
}
