import { Col, Container, Row } from "react-bootstrap";
import { COLOR_DARK_BLUE, COLOR_WHITE } from "./ChartConstants";
import { format } from "date-fns";

export function SessionMetaDataBlock(props) {
  let metaData = props.metaData;

  let formattedUploadDate = null;

  if (metaData === null) {
    metaData = {};
  }

  if (metaData !== undefined) {
    if (metaData.uploader) {
      const uploadDate = new Date(metaData.upload_timestamp * 1000);
      formattedUploadDate = format(uploadDate, "MM/dd/yyyy");
    }
  }

  return (
    <Col
      className="col-4 me-1 p-2"
      style={{
        backgroundColor: COLOR_WHITE,
        borderStyle: "solid",
        borderColor: COLOR_DARK_BLUE,
        borderRadius: 12,
        borderWidth: 6,
      }}
    >
      <Container>
        <Row>
          <Col className="text-center">
            <h3 style={{ color: COLOR_DARK_BLUE, fontWeight: "bold" }}>
              BrainSpace
            </h3>
          </Col>
        </Row>
        <Row>
          <Col>Hospital ID:</Col> <Col>{metaData.hospital_identifier}</Col>
        </Row>
        <Row>
          <Col>Console S/N:</Col> <Col>{metaData.console_sn}</Col>
        </Row>
        <Row>
          <Col>Disposable S/N:</Col> <Col>{metaData.cartridge_sn}</Col>
        </Row>
        <Row>
          <Col>Patient ID:</Col> <Col>{metaData.patient_id}</Col>
        </Row>
        <Row>
          <Col>Added to Cloud:</Col> <Col>{formattedUploadDate}</Col>
        </Row>
        <Row>
          <Col>Uploaded by:</Col> <Col>{metaData.uploader}</Col>
        </Row>
        <Row>
          <Col>Firmware Version:</Col> <Col>{metaData.firmware_version}</Col>
        </Row>
      </Container>
    </Col>
  );
}
