import React, { useState } from "react";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { Col, Container, Row } from "react-bootstrap";
import { MiscFns as DashboardFns } from "./DashboardFns";

export function UploadFiles(props) {
  const [files, setFiles] = useState([]);

  DashboardFns.moveSecretToCookie();

  return (
    <Container className="m-5">
      <Row>
        <Col className="mb-4">
          From the SD card's "Session" Folder, Drag or Select the session
          sub-folder (ex: CRT00000-230505-094133)
        </Col>
      </Row>
      <Row>
        <Col>
          <FilePond
            files={files}
            onupdatefiles={setFiles}
            allowMultiple={true}
            maxFiles={20}
            server="/api/upload"
            name="files"
            allowRevert={false}
            allowRemove={false}
            credits={false}
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          />
        </Col>
      </Row>
      <Row>
        <Col>
          Sessions consist of 4 files with the following extensions:
          <ul>
            <li>.samples</li>
            <li>.extevents</li>
            <li>.thumb</li>
            <li>.thumbevents</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}
