import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import {
  LS_PRESSURE_UNIT_CM_H2O,
  LS_PRESSURE_UNIT_HPA,
  LS_PRESSURE_UNIT_MM_HG,
  LS_VOLUME_UNIT_CC_HR,
  LS_VOLUME_UNIT_ML_HR,
  UnitOfMeasurementExpert,
} from "./UnitOfMeasurementExpert";

function MyRadioButton(props) {
  const groupName = props.groupName;
  const value = props.value;
  const label = props.label;
  const currentValue = props.current;

  return (
    <>
      <input
        className="ms-3 me-2 form-check-input"
        type="radio"
        value={value}
        name={groupName}
        checked={currentValue === value}
      />
      <label className="form-check-label">{label}</label>
      <br />
    </>
  );
}

export function Settings(props) {
  const [pressureUnits, setPressureUnits] = useState(
    UnitOfMeasurementExpert.getPressureUnitCode(),
  );
  const [volumeUnits, setVolumeUnits] = useState(
    UnitOfMeasurementExpert.getVolumePerHourUnitCode(),
  );

  function onPressureUnitsChanged(e) {
    let newValue = e.target.value;
    setPressureUnits(newValue);
    UnitOfMeasurementExpert.setPressureUnitCode(newValue);
  }

  function onVolumeUnitsChanged(e) {
    let newValue = e.target.value;
    setVolumeUnits(newValue);
    UnitOfMeasurementExpert.setVolumePerTimeUnitCode(newValue);
  }

  return (
    <Container className="mt-3">
      <Row>
        <Col>
          <h2>Pressure Units</h2>
          <div onChange={onPressureUnitsChanged}>
            <MyRadioButton
              groupName={"pressureUnits"}
              value={LS_PRESSURE_UNIT_CM_H2O}
              label="cmH2O"
              current={pressureUnits}
            />
            <MyRadioButton
              groupName={"pressureUnits"}
              value={LS_PRESSURE_UNIT_MM_HG}
              label="mmHg"
              current={pressureUnits}
            />
            <MyRadioButton
              groupName={"pressureUnits"}
              value={LS_PRESSURE_UNIT_HPA}
              label="hPa"
              current={pressureUnits}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <h2>Volume Units</h2>
          <div onChange={onVolumeUnitsChanged}>
            <MyRadioButton
              groupName={"volumeUnits"}
              value={LS_VOLUME_UNIT_ML_HR}
              label="ml/hr"
              current={volumeUnits}
            />
            <MyRadioButton
              groupName={"volumeUnits"}
              value={LS_VOLUME_UNIT_CC_HR}
              label="cc/hr"
              current={volumeUnits}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
