import { createBrowserRouter } from "react-router-dom";
import React from "react";
import { SessionList } from "./SessionList";
import { RequestAccess } from "./RequestAccess";
import { VerifyCode } from "./VerifyCode";
import { Root } from "./Root";
import { ErrorPage } from "./ErrorPage";
import { UploadFiles } from "./UploadFiles";
import { SessionSummary } from "./SessionSummary";
import { SessionWaveform } from "./SessionWaveform";
import { Settings } from "./Settings";
import { CartridgeList } from "./CartridgeList";

const router = createBrowserRouter([
  {
    path: "request_access",
    element: <RequestAccess />,
    errorElement: <ErrorPage />,
  },
  {
    path: "verify_code",
    element: <VerifyCode />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "session_list",
        element: <SessionList />,
        errorElement: <ErrorPage />,
      },
      {
        path: "session_historical_averages/:sessionId/:bucketSizeParam",
        element: <SessionSummary />,
        errorElement: <ErrorPage />,
      },
      {
        path: "session_historical_averages/:sessionId/:bucketSizeParam/:timestampCenterParam",
        element: <SessionSummary />,
        errorElement: <ErrorPage />,
      },
      {
        path: "cartridge_historical_averages/:cartridgeDbId/:bucketSizeParam",
        element: <SessionSummary />,
        errorElement: <ErrorPage />,
      },
      {
        path: "cartridge_historical_averages/:cartridgeDbId/:bucketSizeParam/:timestampCenterParam",
        element: <SessionSummary />,
        errorElement: <ErrorPage />,
      },
      {
        path: "session_waveform/:sessionId/:timestampCenterParamString",
        element: <SessionWaveform />,
        errorElement: <ErrorPage />,
      },
      {
        path: "cartridge_waveform/:cartridgeDbId/:timestampCenterParamString",
        element: <SessionWaveform />,
        errorElement: <ErrorPage />,
      },
      {
        path: "upload_files",
        element: <UploadFiles />,
        errorElement: <ErrorPage />,
      },
      {
        path: "",
        element: <SessionList />,
        errorElement: <ErrorPage />,
      },
      {
        path: "cartridge_list",
        element: <CartridgeList />,
        errorElement: <ErrorPage />,
      },
      {
        path: "settings",
        element: <Settings />,
        errorElement: <ErrorPage />,
      },
    ],
  },
]);

export default router;
