import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useNavigate } from "react-router-dom";

export function BrainSpaceNavBar(props) {
  const navigate = useNavigate();

  function onClick() {
    navigate("/");
  }

  let additionalNavBarItems = null;
  let title = null;

  const navbarExtras = props.navbarExtras;

  if (navbarExtras) {
    additionalNavBarItems = navbarExtras.navLink;
    if (navbarExtras.title) {
      title = (
        <Navbar.Brand className="ms-1">- {navbarExtras.title}</Navbar.Brand>
      );
    }
  }

  return (
    <Navbar className="p-3" bg="light" expand="lg">
      <Navbar.Brand className="me-1" onClick={onClick}>
        BrainSpace
      </Navbar.Brand>
      {title}
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto justify-content-end" style={{ width: "100%" }}>
          {/*<NavDropdown title="View" id="basic-nav-dropdown" alignRight>*/}
          {additionalNavBarItems}
          <Nav.Link href="/">Sessions</Nav.Link>
          <Nav.Link href="/cartridge_list">Cartridges</Nav.Link>
          {/*</NavDropdown>*/}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
