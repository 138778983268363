import { Outlet } from "react-router-dom";
import { BrainSpaceNavBar } from "./BrainSpaceNavBar";
import { useState } from "react";

export function Root(props) {
  const [navbarExtras, setNavbarExtras] = useState(null);
  return (
    <>
      <BrainSpaceNavBar navbarExtras={navbarExtras} />
      <Outlet context={[navbarExtras, setNavbarExtras]} />
    </>
  );
}
