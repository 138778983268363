import { Container } from "react-bootstrap";
import React from "react";

export function ErrorMessage(props) {
  const errorMessage = props.errorMessage;

  return (
    <Container className="mt-5">
      <h1>Error</h1>
      {errorMessage}
    </Container>
  );
}
