import { format } from "date-fns";

export function titleFromMetaData(metaData) {
  const name = "Brainspace";
  if (metaData !== undefined && metaData !== null) {
    return name + " - " + metaData.cartridge_sn;
  } else {
    return name;
  }
}

export function uploadedByFromMetaData(metaData) {
  if (metaData !== undefined) {
    if (metaData.uploader) {
      const uploadDate = new Date(metaData.upload_timestamp * 1000);
      const formattedUploadDate = format(uploadDate, "MM/dd/yyyy");
      return "Uploaded by " + metaData.uploader + " on " + formattedUploadDate;
    }
  } else {
    return null;
  }
}
