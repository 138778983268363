export class MiscFns {
  static arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  static humanReadableAge(unix_timestamp) {
    const age_in_seconds = Math.floor(Date.now() / 1000 - unix_timestamp);
    return this.humanReadableDuration(age_in_seconds) + " ago";
  }

  static humanReadableDuration(seconds) {
    if (seconds < 100) {
      if (seconds === 1) {
        return "1 second";
      } else {
        return seconds + " seconds";
      }
    }
    seconds = Math.floor(seconds);
    if (seconds < 120) {
      if (seconds === 1) {
        return "1 second";
      } else {
        return seconds + " seconds";
      }
    }

    const minutes = Math.round(seconds / 60);
    if (minutes < 120) {
      return minutes + " minutes";
    }

    const hours = Math.round(minutes / 60);
    if (hours < 48) {
      return hours + " hours";
    }

    const days = Math.round(hours / 24);
    return days + " days";
  }

  static getUnixTimeFromTimeString(timeString) {
    return new Date(timeString).getTime() / 1000;
  }

  static bisectLeft(arr, value) {
    let low = 0;
    let high = arr.length;
    while (low < high) {
      const middle = (low + high) >> 1;
      if (arr[middle] < value) {
        low = middle + 1;
      } else {
        high = middle;
      }
    }
    return low;
  }

  static convertDegreesToRadians(angle) {
    return (angle * Math.PI) / 180;
  }
}

export function ensureDateIsMillis(input) {
  if (typeof input === "number") {
    return input;
  }
  return MiscFns.getUnixTimeFromTimeString(input) * 1000;
}

export function parseDateParam(input) {
  if (input == null || input === undefined || input === "" || input === null) {
    return null;
  }
  return parseInt(input);
}

export function binarySearch(arrayToSearch, key) {
  let low = 0;
  let high = arrayToSearch.length - 1;
  while (low <= high) {
    let k = (high + low) >> 1;
    let cmp = key - arrayToSearch[k];
    if (cmp > 0) {
      low = k + 1;
    } else if (cmp < 0) {
      high = k - 1;
    } else {
      return k;
    }
  }
  return ~low;
}

export function translateClinicalEventKey(key) {
  switch (key) {
    case "3":
      return "Start Drainage";
    case "4":
      return "Stop Drainage";
    case "5":
      return "Drop";
    case "6":
      return "Alarm";
    case "7":
      return "Alarm Cleared";
    case "8":
      return "Alarm Muted";
    case "12":
      return "Alarm Acknowledged";
    case "14":
      return "Autoclamp Requested";
    case "15":
      return "Autoclamp Released";

    case "2_0":
      return "Position Change";
    case "2_1":
      return "ICP Change";
    case "2_2":
      return "Waveform Manipulation";
    case "2_3":
      return "Troubleshooting Start";
    case "2_4":
      return "Troubleshooting Stop";
    case "2_5":
      return "CA - Other";

    case "2_6":
      return "Medication";
    case "2_7":
      return "Catheter Issue";
    case "2_8":
      return "Troubleshoot";
    case "2_9":
      return "CSF Color";
    case "2_10":
      return "CSF Sample";
    case "2_11":
      return "None";

    case "0":
      return "Settings Change";
    case "M_0":
      return "Session Start (Pressure)";
    case "M_1":
      return "Session Start (Volume - Top of Hour)";
    case "M_2":
      return "Session Start (Volume - Continuous)";

    case "1_0":
      return "Preset 1 Selected";
    case "1_1":
      return "Preset 1 Selected";
    case "1_2":
      return "Preset 1 Selected";
    case "1_3":
      return "Preset 1 Selected";

    default:
      return "Unknown (" + key + ")";
  }
}
