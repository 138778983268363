import { Button, Form, Spinner } from "react-bootstrap";
import React, { useState } from "react";

const REQUEST_ACCESS_STATE_WAITING_FOR_EMAIL = "waiting for email";
const REQUEST_ACCESS_STATE_SENT_REQUEST = "send request";
const REQUEST_ACCESS_STATE_RECEIVED_RESPONSE = "received response";

export function RequestAccess() {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [requestAccessRequestState, setRequestAccessRequestState] = useState(
    REQUEST_ACCESS_STATE_WAITING_FOR_EMAIL,
  );

  let clientSecret = localStorage.getItem("clientSecret");

  if (clientSecret == null) {
    const randomBytes = new Uint8Array(32);
    window.crypto.getRandomValues(randomBytes);
    clientSecret = Array.from(randomBytes, (byte) =>
      byte.toString(16).padStart(2, "0"),
    ).join("");
    localStorage.setItem("clientSecret", clientSecret);
  }

  if (requestAccessRequestState === REQUEST_ACCESS_STATE_SENT_REQUEST) {
  }

  if (requestAccessRequestState === REQUEST_ACCESS_STATE_RECEIVED_RESPONSE) {
    window.location.href = "/verify_code";
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (requestAccessRequestState !== REQUEST_ACCESS_STATE_WAITING_FOR_EMAIL) {
      console.log("Ignoring duplicate request");
      return;
    }

    const data = {
      email_address: email,
      client_secret: clientSecret,
    };

    const url = "/api/request_access";

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(() => {
        setRequestAccessRequestState(REQUEST_ACCESS_STATE_RECEIVED_RESPONSE);
      })
      .catch((error) => {
        setRequestAccessRequestState(REQUEST_ACCESS_STATE_WAITING_FOR_EMAIL);
        setError(error);
      });

    setRequestAccessRequestState(REQUEST_ACCESS_STATE_SENT_REQUEST);
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  if (error != null) {
    console.log("Error: " + error);
    console.log(error);
    // TOOD: Post error message
  }

  let spinner = null;
  if (requestAccessRequestState !== REQUEST_ACCESS_STATE_WAITING_FOR_EMAIL) {
    spinner = (
      <Spinner
        className="me-3"
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    );
  }

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Form onSubmit={handleSubmit} className="p-5 border rounded">
        <h3 className="mb-3">Request Access</h3>
        <Form.Group controlId="formEmail">
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={handleChange}
            className="mb-3"
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="w-100">
          {spinner} Request Access
        </Button>
      </Form>
    </div>
  );
}
