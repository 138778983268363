export const TABLEAU_GREEN = "#59A14F";
export const TABLEAU_RED = "#e15759";
export const TABLEAU_BLUE = "#4e79a7";
export const TABLEAU_SEA_GREEN = "#76b7b2";
export const TABLEAU_LIGHT_GREEN = "#8cd17d";
export const TABLEAU_ORANGE = "#f28e2b";

export const COLOR_LIGHT_BLUE = "#25B3FD";
export const COLOR_DARK_BLUE = "#213D6C";

export const COLOR_PRESSURE = "#BA60A1";
export const COLOR_VOLUME = COLOR_LIGHT_BLUE;
export const COLOR_CHART_BACKGROUND = COLOR_DARK_BLUE;
export const COLOR_WHITE = "white";

export const CHART_MARGINS = {
  l: 0,
  r: 0,
  t: 0,
  b: 0,
  pad: 0,
};

export const WHITE_AXIS = {
  tickcolor: COLOR_WHITE,
  titlefont: {
    color: COLOR_WHITE,
  },
  tickfont: {
    color: COLOR_WHITE,
  },
  linecolor: COLOR_WHITE,
  zeroLineColor: {
    color: COLOR_WHITE,
  },
};

export const WHITE_GRID = {
  grid: {
    color: "gray",
  },
  zerolinecolor: "white",
  gridcolor: "gray",
};

export const PLOTLY_DEFAULT_LAYOUT = {
  plot_bgcolor: COLOR_CHART_BACKGROUND,
  paper_bgcolor: COLOR_CHART_BACKGROUND,
  margin: CHART_MARGINS,
  font: {
    family: "Arial",
    size: 16,
  },
  legend: {
    font: {
      family: "Arial",
      size: 12,
      color: COLOR_WHITE,
    },
    x: 1.1,
  },
};

export const CHART_CONFIG = {
  displayModeBar: false,
  showTips: false,
};

export const UOM_HPA = 0;
export const UOM_MMHG = 1;
export const UOM_CMH2O = 2;

export const MARKER_SIZE_DRIPS = 8;
export const MARKER_SIZE_STOP_START = 10;
export const MARKER_SIZE_CLINICAL_ANNOTATIONS = 15;
